.header {
  display: flex;
  width: 100vw;
  background-image: var(--background-image);
  background-size: cover;
  background-position: center center;
  &.tall {
    height: 100vh;
  }
  &.small {
    height: 600px;
  }

  h1 {
    font-family: 'BlanchCondensedInline';
    font-size: 50px;
    color: var(--color-white);
    position: absolute;
    top: 40px;
    right: 50px;
    margin: 0px;
    padding: 0px;
    font-weight: lighter;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
  }

  h2 {
    font-family: 'BlanchCaps';
    font-size: 30px;
    color: var(--color-white);
    position: absolute;
    top: 95px;
    right: 50px;
    margin: 0px;
    padding: 0px;
    font-weight: lighter;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
  }
}

@media only screen and (max-width: 480px) {
  .header {
    h1 {
      top: 435px;
      left: 30px;
      right: auto;
    }
    h2 {
      top: 490px;
      left: 30px;
      right: auto;
      text-align: left;
    }
  }
}
