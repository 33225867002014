.gridContent {
  display: grid;
  grid-template-columns: repeat(var(--nb-cols, 3), 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;

  .block {
    background-color: #f7f7f8;
    padding: 40px;
    &.centered {
      text-align: center;
    }
  }

  h4 {
    margin: 0px;
    font-family: 'GeogrotesqueMedium';
    font-weight: bold;
    color: var(--color-green);
    font-size: 23px;
    margin-bottom: 20px;
  }

  p,
  li {
    font-family: 'SanchezRegular';
    font-size: 14px;
    color: #545454;
    padding: 0px;
    margin: 0px;
    text-align: justify;
    margin-bottom: 10px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  li {
    text-align: center;
    background-color: var(--color-white);
    padding: 15px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .gridContent {
    grid-template-columns: repeat(var(--nb-cols-tablet, 2), 1fr);
  }
}

@media only screen and (max-width: 480px) {
  .gridContent {
    grid-template-columns: repeat(var(--nb-cols-mobile, 1), 1fr);
  }
}
