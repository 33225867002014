.part1 {
  font-family: 'GeogrotesqueLight';
  font-weight: lighter;
  letter-spacing: -2px;
  text-transform: uppercase;
  margin-right: 8px;
}

.part2 {
  font-weight: bold;
  letter-spacing: 0px;
  font-family: 'GeogrotesqueMedium';
  text-transform: uppercase;
}

.size1 {
  font-size: 40px;
}

.size2 {
  font-size: 35px;
}

.size3 {
  font-size: 30px;
}

.size4 {
  font-size: 20px;
  .part1 {
    letter-spacing: -1px;
  }
}

.white {
  color: var(--color-white);
}

.grey {
  color: var(--color-grey);
}

.green {
  color: var(--color-green);
}

.centered {
  text-align: center;
  margin: 0px;
}
