.mozaik {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.picture {
  background-size: cover;
  background-position: center;
  background-image: var(--background);
  &:first-child {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
  }
}

.threeCols {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

@media only screen and (max-width: 480px) {
  .mozaik {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .picture {
    &:first-child {
      grid-row: 1;
      grid-column: 1;
    }
  }
}
