.btMenu {
  border: none;
  margin: 0;
  padding: 0;

  display: block;
  background-color: #282828;
  width: 70px;
  height: 70px;
  background-image: url(./assets/menu@2x.png);
  background-size: 30px 22px;
  background-position: center center;
  position: fixed;
  background-repeat: no-repeat;
  top: 136px;
  left: 0px;
  z-index: 10000;
  cursor: pointer;
  &:hover {
    background-color: #333333;
  }
  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background-color: white;
    opacity: 0.2;
    position: absolute;
    top: 0px;
    right: -1px;
  }
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: white;
    opacity: 0.2;
    position: absolute;
    bottom: -1px;
    left: 0px;
  }
}

@media only screen and (max-width: 480px) {
  .btMenu {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 55px;
    height: 55px;
    background-size: 44%;
  }
}

/* nav */
.nav {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  max-width: 450px;
  background-color: #282828;
  height: 100%;
  z-index: 10000;
  overflow: auto;
  -webkit-transform: translate3d(-450px, 0, 0);
  transform: translate3d(-450px, 0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  &.open {
    left: 0px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }
}

.ctn {
  overflow: hidden;
  position: relative;
  min-height: 100%;
}

.closeMenu {
  display: block;
  background-size: 36px 36px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(./assets/close.png);
  background-color: #333333;
  width: 88px;
  height: 88px;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  z-index: 10000;
  &:hover {
    background-color: #252525;
  }
}

.menuTitle {
  margin: 40px;
  color: white;
  font-family: 'BlanchCondensed';
  font-size: 50px;
  font-weight: lighter;
  margin-top: 44px;
  line-height: 50px;
  margin-bottom: 0px;
  text-align: left;
}

.menuList {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
  padding-left: 88px;
  padding-right: 88px;
  margin: auto;
  margin-top: 25%;
  margin-bottom: 250px;

  li {
    line-height: 28px;
    width: 100%;
    display: block;
    text-align: right;
    margin-bottom: 30px;
  }
  li a {
    color: #b2b2b2;
    font-family: 'BlanchCondensed';
    font-size: 40px;
    text-decoration: none;
  }
  li a:hover,
  li.selected a {
    color: #00a453;
  }

  li {
    -webkit-transition: -webkit-transform 0s 0.4s;
    transition: transform 0s 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    -webkit-transform: translate3d(0, 200px, 0);
    transform: translate3d(0, 200px, 0);
  }

  li:nth-child(2) {
    -webkit-transform: translate3d(0, 400px, 0);
    transform: translate3d(0, 400px, 0);
  }

  li:nth-child(3) {
    -webkit-transform: translate3d(0, 600px, 0);
    transform: translate3d(0, 600px, 0);
  }

  li:nth-child(4) {
    -webkit-transform: translate3d(0, 800px, 0);
    transform: translate3d(0, 800px, 0);
  }

  li:nth-child(5) {
    -webkit-transform: translate3d(0, 1000px, 0);
    transform: translate3d(0, 1000px, 0);
  }

  li:nth-child(6) {
    -webkit-transform: translate3d(0, 1200px, 0);
    transform: translate3d(0, 1200px, 0);
  }
}
.open .menuList li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transition-duration: 0.9s;
  transition-duration: 0.9s;
}

.btContacts {
  text-align: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100px;
  a {
    display: inline-block;
    width: 25%;
    height: 100px;
    float: left;
    background-color: #333333;
    color: #bfbfbf;
    vertical-align: middle;
    line-height: 100px;
    font-size: 30px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    &:hover {
      background-color: #252525;
      color: #00a453;
      font-size: 35px;
    }
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 100px;
      background-color: #282828;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 1;
    }
    &:last-child:after {
      display: none;
    }
  }
}
