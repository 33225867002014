.aboutBlock {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: space-between;
}

.picture {
  width: calc(50% - 15px);
  align-self: center;
}

.aboutBlock {
  h4 {
    margin: 0px;
    font-family: 'GeogrotesqueMedium';
    font-weight: bold;
    color: var(--color-green);
    font-size: 23px;
  }

  h5 {
    margin: 0px;
    font-family: 'GeogrotesqueMedium';
    font-weight: bold;
    color: #616162;
    font-size: 20px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  p {
    font-family: 'SanchezRegular';
    font-size: 14px;
    color: #545454;
    padding: 0px;
    margin: 0px;
    text-align: justify;
    margin-bottom: 5px;
  }
}

.aboutText {
  width: calc(50% - 15px);
  background-color: #f7f7f8;
  padding: 40px;
}

.faqTitlte {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .picture,
  .aboutText {
    width: 100%;
    &:first-child {
      margin-bottom: 30px;
    }
  }
  .aboutBlock {
    flex-direction: column;
  }
}
