@keyframes scaleDown {
  from {
    transform: scale(1.3, 1.3);
  }

  to {
    transform: scale(1, 1);
  }
}

.text {
  margin-top: 40px;
  margin-bottom: 30px;
}

.title2 {
  margin-top: 100px;
  margin-bottom: 30px;
}

.subtitle {
  font-family: 'SanchezRegular';
  font-size: 14px;
  color: #545454;
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  .click {
    font-size: 12px;
    margin-top: 5px;
    color: var(--color-green);
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.blockClickable {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--color-grey);
  color: var(--color-white);
  letter-spacing: 0px;
  font-family: 'GeogrotesqueMedium';
  transition: all 0.3s;
}

.programms {
  &:hover {
    .subtitle {
      .nb {
        background-color: var(--color-green);
      }
      .click {
        opacity: 1;
      }
    }
  }
}

.gridItemTitle {
  h4 {
    color: var(--color-lightgrey);
  }
}

.sap {
  display: flex;
  flex-direction: row;
  flex-direction: row-reverse;
}

.logo {
  display: flex;
  width: 100px;
  margin-left: 30px;
}

.sapButton {
  cursor: pointer;
}

.headCell {
  &:not(.disabled) {
    .group.group {
      .selected,
      *:not([disabled])[data-active] {
        background-color: var(--color-green);
        color: var(--color-white);
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.head.head {
  text-align: center;
  .group {
    display: inline-flex;
  }
  &:first-of-type {
    border-bottom: none;
    height: auto;
    padding-top: 20px;
    padding-bottom: 5px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-family: 'GeogrotesqueMedium';
  }
}

.subscriptionHeader.subscriptionHeader {
  height: auto;
  padding: 10px 0px;
  background-color: var(--color-lightgrey);
  color: var(--color-white);
  text-align: center;
  font-size: 16px;
  font-family: 'GeogrotesqueMedium';
  letter-spacing: 0.5px;
}

.typeHeader.typeHeader {
  height: auto;
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: center;
  background: none;
  border-bottom: 0px;
  font-size: 14px;
}

.small {
  font-size: 9px;
}

.price {
  display: inline-block;
  position: relative;
}

.animated {
  .price {
    animation-duration: 0.3s;
    animation-name: scaleDown;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

.strokePrice {
  text-decoration-line: line-through;
  margin-right: 10px;
  opacity: 0.5;
}

.subprice {
  display: inline-flex;
  font-size: 11px;
  margin-top: 20px;
}

.pricesTabWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 40px;
}
.twoColsTable {
  width: calc(50% - 40px);
  flex-direction: column;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 12px;
}

.bodyTab.bodyTab {
  height: auto;
  padding-bottom: 30px;
  text-align: center;
  position: relative;
  font-size: 14px;
  .price {
    position: relative;
    font-size: 18px;
    font-family: 'GeogrotesqueMedium';
    background-color: #edeff5;
    padding: 5px 15px;
    border-radius: 4px;
    border: 1px solid #edeff5;
  }

  .bodyCell.bodyCell {
    > *:first-child {
      padding-top: 15px;
    }
  }
}

.conditions {
  text-align: center;
}

.titleBooking,
.titleCGV {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: 'GeogrotesqueMedium';
  font-weight: 600;
}

.textBooking {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.titleCGV {
  margin-top: 50px;
}
.cgv {
  margin-top: 20px;
  margin-bottom: 30px;
}

.withSap {
  .price:after {
    content: '*';
    position: absolute;
    font-size: 15px;
    top: -5px;
    right: -13px;
  }
}

@media only screen and (max-width: 767px) {
  .head.head {
    flex-direction: column;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    .headCell {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .group {
      display: block;
      margin-top: 5px;
    }
  }

  .pricesTabWrapper {
    flex-direction: column;
    align-items: center;
  }
  .twoColsTable {
    width: 95%;
  }
}
