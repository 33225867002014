.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3000;

  pointer-events: none;
  opacity: 0;

  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

  &.isOpen {
    pointer-events: auto;
    opacity: 1;
  }
}

.modal {
  width: 40vw;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: var(--color-white);
  z-index: 3001;
  padding: 45px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  pointer-events: none;
  opacity: 0;

  transform: translate(-50%, -60%);
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

  &.isOpen {
    transform: translate(-50%, -50%);
    pointer-events: auto;
    opacity: 1;
  }

  .closeButton {
    font-size: 50px;
    color: var(--color-green);
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .modal {
    width: 90vw;
  }
}
