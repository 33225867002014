.section {
  display: flex;
  width: 100vw;

  &.white {
    background-color: var(--color-white);
  }

  &.grey {
    background-color: var(--color-grey);
  }

  &.darkgrey {
    background-color: var(--color-darkgrey);
  }
}

.container {
  width: 80%;
  max-width: 1000px;
  text-align: left;

  &.sizeM {
    margin: 50px auto;
  }

  &.sizeS {
    margin: 30px auto;
  }
}
