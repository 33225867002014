.blockSeparator {
  margin: 100px auto;
  padding-top: 65px;
  padding-bottom: 65px;
  position: relative;
  display: flex;
  align-items: flex-start;
  min-height: 420px;

  &:before {
    content: '';
    display: block;
    width: 100px;
    height: 64px;
    background-image: url(./assets/separator_t_top.png);
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    margin-left: -49px;
    top: 6px;
  }
  &:after {
    content: '';
    display: block;
    width: 100px;
    height: 64px;
    background-image: url(./assets/separator_t_bot.png);
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    margin-left: -49px;
    bottom: 2px;
  }
}

.texts {
  text-align: left;
  transform: translate(-5px, 0px);
  transition: transform 0.5s;
  z-index: 3;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.block {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 70px 50px;
  position: relative;
  border: 0;
  color: var(--color-white);
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-green);
    opacity: 0.7;
    transition: opacity 0.5s;
    z-index: 2;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: var(--background);
    background-position: center;
    background-size: cover;
    transform: scale(1);
    transition: transform 0.5s;
    z-index: 1;
  }

  &:first-child {
    align-self: flex-end;
  }
  &:hover {
    color: var(--color-white);
    &:before {
      opacity: 0.8;
    }
    &:after {
      transform: scale(1.2);
    }
    .texts {
      transform: translate(0px, 0px);
    }
  }
  &:visited,
  &:focus {
    color: var(--color-white);
  }
}

.text1 {
  display: inline-block;
  font-family: 'GeogrotesqueLight';
  font-size: 23px;
  line-height: 20px;
}
.text2 {
  display: inline-block;
  font-family: 'GeogrotesqueMedium';
  font-size: 27px;
  line-height: 30px;
  font-weight: bold;
  letter-spacing: -0.5px;
}

@media only screen and (max-width: 767px) {
  .blockSeparator {
    flex-direction: column;
  }
  .block {
    width: 100%;
    padding: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .text1 {
    font-size: 20px;
    line-height: 17px;
  }
  .text2 {
    font-size: 23px;
    line-height: 20px;
  }
}
