.subheader {
  background-color: var(--color-white);
  padding-top: 30px;
  position: relative;
  padding-bottom: 70px;

  &:after {
    content: '';
    display: block;
    width: 100px;
    height: 64px;
    background-image: url(./assets/separator_t_bot.png);
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    margin-left: -49px;
    bottom: 6px;
  }
}

.wrapper {
  width: 80%;
  max-width: 1000px;
  margin: auto;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -230px;
    left: 66%;
    width: 50vw;
    height: 330px;
    background-color: var(--color-white);
    z-index: 0;
  }
}

.textCtn {
  display: flex;
  text-align: left;
  width: 66%;
  flex-direction: column;
  background-color: var(--color-green);
  color: var(--color-white);
  padding: 25px;

  h2 {
    font-family: 'GeogrotesqueMedium';
    font-size: 18px;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    text-transform: uppercase;
  }
  p {
    font-family: 'SanchezRegular';
    font-size: 13px;
    line-height: 17px;
    color: white;
  }
}

.picture {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 34%;
  height: 330px;
  margin-top: -200px;
  background-image: var(--background);
  background-position: center;
  background-size: cover;
  z-index: 2;
}

.left {
  .wrapper {
    display: flex;
    justify-content: flex-end;
    &:before {
      content: '';
      position: absolute;
      top: -230px;
      left: auto;
      right: 66%;
      width: 50vw;
      height: 330px;
      background-color: var(--color-white);
      z-index: 0;
    }
  }
  .picture {
    right: auto;
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .subheader {
    padding-top: 0;

    &,
    &.left {
      .wrapper {
        width: 100%;
        padding: 30px 30px 0 30px;
        flex-direction: column;
        &:before {
          display: none;
        }
      }

      .picture {
        position: relative;
        margin-top: 0px;
        width: 100%;
      }

      .textCtn {
        width: 100%;
      }
    }
  }
}
