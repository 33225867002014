.blocks {
  text-align: center;
  vertical-align: top;
  margin-top: 30px;
}

.block {
  display: flex;
  text-decoration: none;
  text-align: center;
  padding: 50px 30px;
  position: relative;
  flex-direction: column;
  align-items: center;

  &.highlight {
    cursor: pointer;
  }
  &:not(.highlight) {
    cursor: auto;
  }

  &.withSeparator {
    &:first-child:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      bottom: 0;
      background-color: #ececec;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      bottom: 0;
      background-color: #ececec;
    }
  }

  &:hover,
  &:active,
  &:visited {
    outline: 0;
    border: 0;
    text-decoration: none;
  }

  .text {
    font-family: 'BlanchCaps';
    font-size: 30px;
    line-height: 20px;
    margin-top: 20px;
    display: inline-block;
    color: var(--color-grey);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
  }

  .icon {
    display: block;
    margin: auto;
    font-size: 45px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    color: var(--color-grey);
  }

  &.leftIcon {
    flex-direction: row;
    justify-content: center;
    text-align: left;
    .icon {
      align-self: flex-start;
      display: flex;
      margin: inherit;
      font-size: 35px;
      margin-right: 30px;
    }
    .text {
      margin-top: 0px;
    }
  }

  .small {
    color: var(--color-lightgrey); //#a6a6a6;
    font-size: 20px;
  }

  &.highlight:hover {
    background-color: #f9f9f9; //#494949;
    &:not(.leftIcon) {
      &.withIcon {
        .icon {
          color: var(--color-green);
          -webkit-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
        }
        .text {
          -webkit-transform: translate3d(0, 10px, 0);
          transform: translate3d(0, 10px, 0);
        }
      }
      &:not(.withIcon) {
        .text {
          -webkit-transform: translate3d(0, -10px, 0);
          transform: translate3d(0, -10px, 0);
        }
      }
    }
  }
}

.marginM {
  margin-top: 30px;
  .block {
    padding: 50px 30px;
  }
}
.marginS {
  margin-top: 10px;
  .block {
    padding: 20px 20px;
  }
}

.darkMode {
  background-color: var(--color-grey);
  .block {
    &:first-child:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      bottom: 0;
      background-color: var(--color-lightgrey);
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      bottom: 0;
      background-color: var(--color-lightgrey);
    }

    &.highlight:hover {
      background-color: #494949;
      .icon {
        color: var(--color-green);
      }
    }

    .text {
      color: var(--color-white);
    }
    .icon {
      color: #bfbfbf;
    }
    .small {
      color: #a6a6a6;
    }
  }
}

@media only screen and (max-width: 767px) {
  .block {
    padding: 30px 20px;

    &.withSeparator {
      &:first-child:before {
        content: '';
        position: absolute;
        bottom: auto;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #ececec;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        top: auto;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background-color: #ececec;
      }
    }
  }
}
