.text {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.subtitle {
  font-family: 'SanchezRegular';
  font-size: 14px;
  color: #545454;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.price {
  margin-bottom: 5px;
}
