.footer {
  font-size: 15px;
  font-family: 'GeogrotesqueLight';
  font-weight: normal;

  a {
    color: #bfbfbf;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      color: #00a453;
      text-decoration: none;
    }
  }
}

.left {
  text-align: left;
}
