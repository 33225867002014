.logo {
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 30px;
  background-color: white;
  color: #282828;
  font-size: 50px;
  text-align: center;
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #282828;
  cursor: pointer;

  span {
    line-height: 37px;
  }
}

.part1 {
  font-family: 'BlanchCondensed';
  display: block;
}

.part2 {
  font-family: 'BlanchCondensedInline';
  display: block;
}

@media only screen and (max-width: 480px) {
  .logo {
    position: absolute;
    width: 100%;
    padding: 0px;
    height: 55px;
    line-height: 35px;
    padding-left: 70px;
    padding-right: 20px;
    text-align: left;
    display: block;
  }
  .part1,
  .part2 {
    display: inline-block;
    // float: left;
    font-size: 35px;
    padding-top: 8px;
    justify-content: center;
  }
  .part1 {
    margin-right: 5px;
  }
}
