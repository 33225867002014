/*------------------------------------*\
    THEME
\*------------------------------------*/

:root {
  --color-green: #00a453;
  --color-white: white;
  --color-lightgrey: #5c5c5c;
  --color-grey: #454545;
  --color-darkgrey: #333333;
}

/*------------------------------------*\
    MAIN
\*------------------------------------*/

/* global box-sizing */
*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
/* html element 62.5% font-size for REM use */
html {
  font-size: 62.5%;
  height: 100%;
}
body {
  font:
    300 11px/1.4 'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif;
  color: #444;
  height: 100%;
  margin: 0px;
  padding: 0px;
  width: 100%;
  background-color: #333333;
  overflow-x: hidden;
}
/* clear */
.clear:before,
.clear:after {
  content: ' ';
  display: table;
}

.clear:after {
  clear: both;
}
.clear {
  *zoom: 1;
}
img {
  max-width: 100%;
  vertical-align: bottom;
}
a {
  color: #00a453;
  text-decoration: none;
}
a:hover {
  color: #00a453;
  text-decoration: none;
}
a:focus {
  color: #00a453;
  outline: 0;
  text-decoration: none;
}
a:hover,
a:active,
a:visited {
  color: #00a453;
  outline: 0;
  text-decoration: none;
}
input:focus {
  outline: 0;
  border: 1px solid #04a4cc;
  text-decoration: none;
}

/*------------------------------------*\
	TYPOGRAPHY
\*------------------------------------*/

@font-face {
  font-family: 'BlanchCapsInline';
  src: url('./fonts/blanch-caps-inline.eot');
  src:
    url('./fonts/blanch-caps-inline.eot?#iefix') format('embedded-opentype'),
    url('./fonts/blanch-caps-inline.woff') format('woff'),
    url('./fonts/blanch-caps-inline.ttf') format('truetype'),
    url('./fonts/blanch-caps-inline.svg#blanchcaps_inline') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BlanchCapsLight';
  src: url('./fonts/blanch-caps-light.eot');
  src:
    url('./fonts/blanch-caps-light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/blanch-caps-light.woff') format('woff'),
    url('./fonts/blanch-caps-light.ttf') format('truetype'),
    url('./fonts/blanch-caps-light.svg#blanchcaps_light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BlanchCaps';
  src: url('./fonts/blanch-caps.eot');
  src:
    url('./fonts/blanch-caps.eot?#iefix') format('embedded-opentype'),
    url('./fonts/blanch-caps.woff') format('woff'),
    url('./fonts/blanch-caps.ttf') format('truetype'),
    url('./fonts/blanch-caps.svg#blanchcaps') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BlanchCondensedInline';
  src: url('./fonts/blanch-condensed-inline.eot');
  src:
    url('./fonts/blanch-condensed-inline.eot?#iefix') format('embedded-opentype'),
    url('./fonts/blanch-condensed-inline.woff') format('woff'),
    url('./fonts/blanch-condensed-inline.ttf') format('truetype'),
    url('./fonts/blanch-condensed-inline.svg#blanchcondensed_inline') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BlanchCondensedLight';
  src: url('./fonts/blanch-condensed-light.eot');
  src:
    url('./fonts/blanch-condensed-light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/blanch-condensed-light.woff') format('woff'),
    url('./fonts/blanch-condensed-light.ttf') format('truetype'),
    url('./fonts/blanch-condensed-light.svg#blanchcondensed_light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BlanchCondensed';
  src: url('./fonts/blanch-condensed.eot');
  src:
    url('./fonts/blanch-condensed.eot?#iefix') format('embedded-opentype'),
    url('./fonts/blanch-condensed.woff') format('woff'),
    url('./fonts/blanch-condensed.ttf') format('truetype'),
    url('./fonts/blanch-condensed.svg#blanchcondensed') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GeogrotesqueLight';
  src: url('./fonts/Geogtq-Lg.eot');
  src:
    url('./fonts/Geogtq-Lg.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Geogtq-Lg.woff') format('woff'),
    url('./fonts/Geogtq-Lg.ttf') format('truetype'),
    url('./fonts/Geogtq-Lg.svg#geogrotesque_lglight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GeogrotesqueMedium';
  src: url('./fonts/Geogtq-Md.eot');
  src:
    url('./fonts/Geogtq-Md.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Geogtq-Md.woff') format('woff'),
    url('./fonts/Geogtq-Md.ttf') format('truetype'),
    url('./fonts/Geogtq-Md.svg#geogrotesque_mdmedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GeogrotesqueBold';
  src: url('./fonts/Geogtq-Ul.eot');
  src:
    url('./fonts/Geogtq-Ul.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Geogtq-Ul.woff') format('woff'),
    url('./fonts/Geogtq-Ul.ttf') format('truetype'),
    url('./fonts/Geogtq-Ul.svg#geogrotesque_ullgultralight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SanchezBold';
  src: url('./fonts/sanchez-bold.eot');
  src:
    url('./fonts/sanchez-bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/sanchez-bold.woff') format('woff'),
    url('./fonts/sanchez-bold.ttf') format('truetype'),
    url('./fonts/sanchez-bold.svg#font') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SanchezRegular';
  src: url('./fonts/Sanchez-Regular.eot');
  src:
    url('./fonts/Sanchez-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Sanchez-Regular.woff') format('woff'),
    url('./fonts/Sanchez-Regular.ttf') format('truetype'),
    url('./fonts/Sanchez-Regular.svg#sanchezregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon/icomoon.eot?-rdnm34');
  src:
    url('./fonts/icomoon/icomoon.eot?#iefix-rdnm34') format('embedded-opentype'),
    url('./fonts/icomoon/icomoon.woff?-rdnm34') format('woff'),
    url('./fonts/icomoon/icomoon.ttf?-rdnm34') format('truetype'),
    url('./fonts/icomoon/icomoon.svg?-rdnm34#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
